.about-section {
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
}



.about-text {
    margin-top: 3rem;
    text-align: center;
    font-size: 4rem;
}

.about-text-long {
    text-align: center;
    font-size: 1.2rem;
}


.about-card {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    height: 20rem;
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 16px;
    padding-block: 60px;
    transition: 500ms;
    color: wheat;
    background-color: #1C1C1E;
    width: 80%;
}

.about-title {
    font-size: 2rem;
    width: 80%;
}


.about-card:hover {
    background-color: wheat;
    color: black;
    transition: 500ms;
    border-radius: 16px;
    transform: translateY(-5px) scale(1.005) translateZ(0);
}


.about-card p {
    font-size: 1rem;
    margin-top: 20px;
    color: white;
    text-align: center;
    width: 80%;
    margin-inline: auto;
}

.about-card:hover p{

    color: black;
    opacity: 70%;
    transition: 500ms;

}

.row1 {
    display: flex;
    align-items: center;
    gap: 5rem;
    margin-inline: 5rem;
    justify-content: center;
}

.row2 {
    display: flex;
    gap: 5rem;
    align-items: center;
    margin-inline: 5rem;
    justify-content: center;
}

@media (max-width: 400px){
    .about-card {
        font-size: 1rem;
        margin-top: 20px;
        color: black;
        text-align: center;
        height: 150px;
        width: 50rem;
        padding-block: 10px;
        border: 2px solid #5D3FD3;
        background-color: white;
    }

    .about-card p{
        font-size: 8px;
        margin-top: 20px;
        color: black;
        text-align: center;
        width: auto;
    }
    

    .about-title {
        font-size: 20px;
        width: 2rem;
        margin: 0px;
        padding: 0px;
    }

    .row1 {
        display: flex;
        gap: 0rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-inline: 20px;
    }
    
    .row2 {
        display: flex;
        gap: 0rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-inline: 20px;
    }
}