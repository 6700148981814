.footer-container{
    width: 80%;
    height: 80vh;
    margin: auto;
    margin-top: 10rem;
    display: flex;
}

.text-top {
    margin-top: 2rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

.text-bottom {
    text-align: center;
    font-size: 1rem;
}

.footer-img {
    height: 30rem;
    width: 40rem;
    background-image: url(../assets/img/contact.png);
    background-size: contain;
    margin-left: 5rem;
}

.contact-box {
    margin: 1rem;
    margin-left: 5rem;
    height: 70vh;
    width: 50%;
    background-image: url(../assets/img/bg.jpg);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.contact-box p {
    display: flex;
    flex-direction: column;
    margin-block: 4rem;
    font-size: 4rem;
    text-align: center;
}

.input-box1 {
    width: 20rem;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 3px solid #5D3FD3;
    margin-bottom: 1rem;
}

.input-box2 {
    width: 20rem;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 3px solid #5D3FD3;

    margin-bottom: 1rem;
}
.input-box3 {
    width: 20rem;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 3px solid #5D3FD3;

    margin-bottom: 1rem;
}


.input-mesg {
    width: 30rem;
    height: 10rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 0.18rem solid #5D3FD3;
    line-height: 5;
}

.blank-fields {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.comic-button {
    width: 15rem;
    margin-top: 10px;
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: black;
    border: 2px solid #000;
    border-radius: 10px;
    box-shadow: 5px 5px 0px #000;
    transition: all 0.3s ease;
  }
  
  .comic-button:hover {
    background-color: #fff;
    color: black;
    border: 2px solid black;
    box-shadow: 5px 5px 0px black;
  }
  
  .comic-button:active {
    background-color: white;
    box-shadow: none;
    color: black;
    transform: translateY(4px);
  }
  

  @media screen and (max-width:400px){
    .footer-img,
    .text-top,
    .text-bottom{
        display: none;
    }


    .footer-container {
        padding: 0;
        margin: 0;
        height: 100vh;
        width: 100vw;
        display: block;
        position: absolute;
        
    }

    .contact-box {
        padding: 0;
        margin: 0;
        width: 100%;
        background-image: none;
    }

    .contact-box P{
        font-size: 3rem;
        padding: 0px;
        margin-block: 40px;
    }

    .box1 {
        border: 2px solid #5D3FD3;
        width: 100%;
    }

    .mesg {
        width: 20rem;
    }

    .comic-button {
        width: 10rem;
        margin-top: 10px;
        display: inline-block;
        padding: 10px 20px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        color: black;
        background-color: white;
        border: 2px solid black;
        border-radius: 10px;
        box-shadow: 5px 5px 0px #000;
        transition: all 0.3s ease;
      }
      
  }