.intro{
    height: 100vh;
}
.navbar {
    display: flex;
    justify-content: space-evenly;
}

.nav-items{
    display: flex;
    margin-inline: 1rem;
}


.nav-items li{
    padding: 2rem;
    list-style: none;
    margin-right: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    transition: 400ms;
}

.nav-items a {
    color: black;
}

.nav-items li:hover{
    color: #5a189a;
    transition: 400ms;
    cursor: pointer;
}

.bilab {
    font-size: 5rem;
}
   
.download-cv {
 max-width: 150px;
 min-height: 50px;
 border-radius: 10px;
 border: 1px solid #865DFF;
 position: relative;
 overflow: hidden;
 transition: all 0.5s ease-in;
 z-index: 1;
}

.download-cv::before,
.download-cv::after {
 content: '';
 position: absolute;
 top: 0;
 max-width: 0;
 min-height: 100%;
 transform: skew(15deg);
 transition: all 0.5s;
 overflow: hidden;
 z-index: -1;
}

.download-cv::before {
 left: -10px;
 background: #191825;
}

.download-cv::after {
 right: -10px;
 background: #5a189a;
}

.download-cv:hover::before,
.download-cv:hover::after {
 max-width: 58%;
}

.download-cv:hover {
 color: white;
 transition: 0.3s;
}

.download-cv  {
 color: #FFA3FD;
 font-size: 1.05rem;
 font-weight: 500;
 transition: all 0.3s ease-in;
}

.download-cv {
    color: black;
}

.intro-text1 {
    margin-top: 10rem;
    padding-left: 10rem;
    font-size: 3rem;
}


.fa-solid {
    display: none;
}


@media (max-width: 400px){

    .navbar {
        display: none;
    }
/* 
    .navbar{
        position: absolute;
        width: 100%;
        height: 100vh;
        background: grey;
        width: 70%;
        top: 0;
        right: 0px;
        text-align: left;
        flex-direction: column;
        z-index: 1;
        transition: 1s;
    } */

    .nav-items {
        flex-direction: column;
    }

    .intro {
        height: 100vh;
    }

    .intro-text1 {
        font-size: 3rem;
        padding-left: 3rem;
        margin-top: 70%;
        text-align: left;
    }

    .fa-solid{
        display: block;
        color: #5D3FD3;
        font-size: 3rem;
        cursor: pointer;
        margin: 10px;
    }

    .nav-items ul li{
        display: block;
        padding-left: 30px;
    }


    .hamburger {
        position: absolute;
        height: 3rem;
        width: 3rem;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        z-index: 2;  
    }

    .hamburger-active .navbar {
        display: block;
      }
    
}

