.portfolio-container {
    display: flex;
    height: 38rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}

.projects {
  margin-block: 3rem;
  text-align: center;
  font-size: 4rem;
}

.cards {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  
  .cards .card {
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 25rem;
    width: 25rem;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    transition: 400ms;
    border: 0.3rem solid black;
  }

  .cards a{
    color: transparent;
  }
  
  .cards .card p.tip {
    font-size: 2rem;
    font-weight: 700;
    color: black;
  }
  
  .cards .card p.second-text {
    font-size: 1rem;
    color: black;
    width: 90%;
    text-align: center;
  }
  
  .cards .card:hover {
    transform: scale(1.1, 1.1);
    background-color: black;
  }

  .cards .card:hover p.tip{
    color: white;
  } 
  .cards .card:hover p.second-text{
    color: white;
  }

  .cards:hover .card:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
  }


  @media screen and (max-width:400px){
    .portfolio-container{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
    }   

    .portfolio-container{
      flex-direction: row;
    }
    .projects {
      margin-block: 1rem;
      text-align: center;
      font-size: 3rem;
    }

    .cards {
      flex-wrap: wrap;
      gap: 1rem;
    }

  
    .cards .card {
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 10rem;
      width: 20rem;
      border-radius: 10px;
      color: black;
      cursor: pointer;
      transition: 400ms;
      border: 0.3rem solid #5D3FD3;
    }

    .cards .card p.tip {
      padding: 0;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 700;
      color: black;
    }
    
    .cards .card p.second-text {
      font-size: 10px;
      color: black;
      width: 90%;
      text-align: center;
    }
    
}